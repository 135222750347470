import React from "react";

export default function AccelexLogoSvg() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="60"
      height="26"
      viewBox="0 0 107 26"
      fill="none"
      className="w-full"
    >
      <path
        d="M13.1973 8.64382H17.1544V24.857H13.1973L13.0189 22.8452C12.5745 23.5695 11.9902 24.1573 11.259 24.6051C10.5277 25.0529 9.66705 25.2769 8.6734 25.2769C7.47333 25.2769 6.35023 25.0494 5.30061 24.5946C4.25098 24.1398 3.33081 23.5135 2.53659 22.7123C1.74238 21.911 1.11961 20.9874 0.671774 19.9413C0.223935 18.8917 0 17.7686 0 16.5685C0 15.4104 0.213451 14.3258 0.640298 13.3111C1.06714 12.2965 1.66543 11.4043 2.43165 10.6346C3.19788 9.86836 4.08655 9.26661 5.09069 8.83627C6.09483 8.40242 7.17595 8.18896 8.33403 8.18896C9.40465 8.18896 10.3633 8.42689 11.21 8.90272C12.0602 9.37855 12.781 9.98381 13.3757 10.7186L13.1973 8.64382ZM8.57543 21.4702C9.41863 21.4702 10.1639 21.2603 10.8111 20.8369C11.4584 20.4171 11.9692 19.8468 12.3366 19.126C12.7039 18.4088 12.8859 17.6146 12.8859 16.7504C12.8859 15.8757 12.7004 15.078 12.3366 14.3573C11.9692 13.64 11.4584 13.0697 10.8041 12.6464C10.1499 12.223 9.40813 12.0131 8.57543 12.0131C7.74273 12.0131 6.9835 12.2265 6.29774 12.6534C5.61199 13.0802 5.06621 13.6505 4.66035 14.3643C4.2545 15.078 4.05157 15.8722 4.05157 16.7469C4.05157 17.6216 4.258 18.4158 4.66736 19.1295C5.07671 19.8433 5.626 20.4101 6.31175 20.8334C7.001 21.2568 7.75322 21.4702 8.57543 21.4702Z"
        fill="#909090"
      />
      <path
        d="M29.9534 19.9763L33.5186 21.9076C32.7734 22.9222 31.8392 23.7409 30.7126 24.3567C29.5895 24.9725 28.3615 25.2804 27.0319 25.2804C25.5415 25.2804 24.1805 24.8955 22.9524 24.1293C21.7244 23.363 20.7482 22.3309 20.017 21.0398C19.2892 19.7488 18.9219 18.3179 18.9219 16.7504C18.9219 15.5609 19.1318 14.4517 19.5551 13.4196C19.9785 12.3875 20.5558 11.4813 21.2975 10.6941C22.0393 9.91036 22.8999 9.29808 23.8831 8.85373C24.8662 8.40939 25.9158 8.18896 27.0284 8.18896C28.358 8.18896 29.586 8.49686 30.7091 9.11264C31.8322 9.72842 32.7699 10.5541 33.5151 11.5932L29.9499 13.5071C29.5721 13.0767 29.1242 12.7409 28.6134 12.5029C28.0991 12.265 27.5742 12.1461 27.0319 12.1461C26.2552 12.1461 25.552 12.3595 24.9257 12.7863C24.2995 13.2132 23.8026 13.7765 23.4353 14.4727C23.0679 15.169 22.8824 15.9282 22.8824 16.7504C22.8824 17.5621 23.0679 18.3144 23.4353 19.0106C23.8026 19.7069 24.2995 20.2666 24.9257 20.69C25.552 21.1133 26.2552 21.3233 27.0319 21.3233C27.5952 21.3233 28.1306 21.2008 28.6379 20.9594C29.1452 20.7145 29.5826 20.3891 29.9534 19.9763Z"
        fill="#909090"
      />
      <path
        d="M44.9519 19.9763L48.5171 21.9076C47.7718 22.9222 46.8377 23.7409 45.7111 24.3567C44.588 24.9725 43.3599 25.2804 42.0304 25.2804C40.5399 25.2804 39.1789 24.8955 37.9544 24.1293C36.7263 23.363 35.7502 22.3309 35.0189 21.0398C34.2912 19.7488 33.9238 18.3179 33.9238 16.7504C33.9238 15.5609 34.1337 14.4517 34.5571 13.4196C34.9804 12.3875 35.5612 11.4813 36.2994 10.6941C37.0412 9.91036 37.9019 9.29808 38.885 8.85373C39.8682 8.40939 40.9178 8.18896 42.0304 8.18896C43.3599 8.18896 44.588 8.49686 45.7111 9.11264C46.8342 9.72842 47.7718 10.5541 48.5171 11.5932L44.9519 13.5071C44.574 13.0767 44.1262 12.7409 43.6153 12.5029C43.101 12.265 42.5762 12.1461 42.0339 12.1461C41.2572 12.1461 40.554 12.3595 39.9277 12.7863C39.3014 13.2132 38.8046 13.7765 38.4372 14.4727C38.0698 15.169 37.8844 15.9282 37.8844 16.7504C37.8844 17.5621 38.0698 18.3144 38.4372 19.0106C38.8046 19.7069 39.3014 20.2666 39.9277 20.69C40.554 21.1133 41.2572 21.3233 42.0339 21.3233C42.5972 21.3233 43.1325 21.2008 43.6398 20.9594C44.1472 20.7145 44.5845 20.3891 44.9519 19.9763Z"
        fill="#909090"
      />
      <path
        d="M57.0323 25.2774C55.5419 25.2774 54.1809 24.8926 52.9563 24.1263C51.7283 23.3601 50.7521 22.328 50.0209 21.03C49.2897 19.7319 48.9258 18.3009 48.9258 16.7335C48.9258 15.5544 49.1357 14.4523 49.559 13.4167C49.9789 12.3846 50.5597 11.4749 51.3014 10.6911C52.0396 9.90743 52.9038 9.29515 53.887 8.85081C54.8701 8.40646 55.9197 8.18604 57.0323 8.18604C58.2954 8.18604 59.457 8.45545 60.5101 8.98726C61.5632 9.52257 62.4589 10.2538 63.1937 11.1845C63.9284 12.1151 64.4637 13.1718 64.7996 14.3613C65.1355 15.5509 65.209 16.793 65.027 18.091H53.1768C53.3272 18.6963 53.5756 19.2386 53.922 19.7214C54.2684 20.2043 54.7057 20.5856 55.234 20.8725C55.7623 21.1594 56.3641 21.3063 57.0323 21.3168C57.7251 21.3273 58.3549 21.1629 58.9217 20.8235C59.4885 20.4841 59.9608 20.0223 60.3387 19.438L64.3762 20.3792C63.7184 21.8172 62.7318 22.9927 61.4268 23.9059C60.1183 24.8226 58.6523 25.2774 57.0323 25.2774ZM53.0438 15.1311H61.0209C60.902 14.4838 60.6501 13.896 60.2687 13.3712C59.8838 12.8464 59.4115 12.43 58.8482 12.1152C58.2849 11.8003 57.6796 11.6463 57.0323 11.6463C56.3851 11.6463 55.7833 11.8003 55.234 12.1082C54.6812 12.416 54.2159 12.8324 53.831 13.3572C53.4462 13.8785 53.1838 14.4698 53.0438 15.1311Z"
        fill="#909090"
      />
      <path
        d="M81.1535 25.2774C79.663 25.2774 78.302 24.8926 77.0774 24.1263C75.8494 23.3601 74.8732 22.328 74.142 21.03C73.4107 19.7319 73.0469 18.3009 73.0469 16.7335C73.0469 15.5544 73.2568 14.4523 73.6802 13.4167C74.1035 12.3846 74.6843 11.4749 75.4225 10.6911C76.1608 9.90743 77.025 9.29515 78.0081 8.85081C78.9913 8.40646 80.0409 8.18604 81.1535 8.18604C82.4165 8.18604 83.5781 8.45545 84.6312 8.98726C85.6843 9.52257 86.58 10.2538 87.3148 11.1845C88.0495 12.1151 88.5848 13.1718 88.9207 14.3613C89.2566 15.5509 89.33 16.793 89.1481 18.091H77.2979C77.4483 18.6963 77.6967 19.2386 78.0431 19.7214C78.3895 20.2043 78.8268 20.5856 79.3551 20.8725C79.8834 21.1594 80.4852 21.3063 81.1535 21.3168C81.8462 21.3273 82.476 21.1629 83.0428 20.8235C83.6096 20.4841 84.0819 20.0223 84.4598 19.438L88.4973 20.3792C87.8396 21.8172 86.8529 22.9927 85.5479 23.9059C84.2394 24.8226 82.7734 25.2774 81.1535 25.2774ZM77.1649 15.1311H85.1421C85.0231 14.4838 84.7712 13.896 84.3863 13.3712C84.0015 12.8464 83.5291 12.43 82.9658 12.1152C82.4025 11.8003 81.7972 11.6463 81.15 11.6463C80.5027 11.6463 79.9009 11.8003 79.3516 12.1082C78.7988 12.416 78.3335 12.8324 77.9486 13.3572C77.5673 13.8785 77.3049 14.4698 77.1649 15.1311Z"
        fill="#909090"
      />
      <path
        d="M70.9824 0.537598H67.0254V24.8574H70.9824V0.537598Z"
        fill="#909090"
      />
      <path
        d="M98.1094 13.5916L95.1389 10.6247L92.172 7.65771L89.2051 10.6247L92.172 13.5916L95.1389 16.562L92.172 19.529L89.2051 22.4924L92.1755 25.4628L95.1389 22.4959L95.1424 22.4924L98.1094 19.529L101.076 16.562L98.1094 13.5916Z"
        fill="#6FBD77"
      />
      <path
        d="M104.035 7.6597L101.068 10.626L104.035 13.5923L107.001 10.626L104.035 7.6597Z"
        fill="#909090"
      />
      <path
        d="M104.035 19.5284L101.068 22.4946L104.035 25.4609L107.001 22.4946L104.035 19.5284Z"
        fill="#909090"
      />
    </svg>
  );
}
