import React from "react";

export default function EdwardArchitectsLogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="232"
      height="24"
      viewBox="0 0 232 24"
      fill="none"
      className="w-full"
    >
      <g clip-path="url(#clip0_522_1991)">
        <path
          d="M0 15.4281C0 10.3198 3.39278 6.78552 8.08253 6.78552C12.7723 6.78552 15.8497 10.0398 15.8497 15.2513V16.5099H3.42815C3.63744 19.3426 5.42373 21.0582 8.22108 21.0582C11.0184 21.0582 12.2447 19.6934 12.8755 18.084L15.9204 19.2748C14.8003 21.8275 12.4569 23.8585 8.22402 23.8585C3.15107 23.8585 0.00294768 20.5011 0.00294768 15.4281H0ZM12.3154 13.9248C12.1769 11.0921 10.5999 9.58582 8.01179 9.58582C5.42373 9.58582 3.70818 11.2306 3.35741 13.9248H12.3125H12.3154Z"
          fill="#F3744A"
        />
        <path
          d="M17.4385 15.3928C17.4385 10.1459 20.5866 6.71481 24.8902 6.71481C27.1629 6.71481 29.017 7.79956 30.2078 9.54753V0H33.5652V23.5814H30.2078V21.1673C29.0523 22.9182 27.1629 23.9322 24.8548 23.9322C20.622 23.9322 17.4385 20.7487 17.4385 15.3957V15.3928ZM30.3847 15.3928V15.1128C30.3847 11.8585 28.6367 9.65365 25.7303 9.65365C22.8239 9.65365 21.0435 11.717 21.0435 15.2867V15.5313C21.0435 19.0656 22.8976 20.9904 25.7303 20.9904C28.563 20.9904 30.3847 18.6824 30.3847 15.3928Z"
          fill="#F3744A"
        />
        <path
          d="M35.21 7.06848H38.7796L41.9631 18.6145L45.4267 7.06848H48.7487L52.2122 18.6499L55.3603 7.06848H58.8946L53.7509 23.5814H50.6735L47.0685 11.266L43.4989 23.5814H40.4185L35.2041 7.06848H35.21Z"
          fill="#F3744A"
        />
        <path
          d="M58.8594 19.2424C58.8594 15.5342 61.6243 14.6941 65.2971 13.8894C68.0974 13.294 69.81 13.1201 70.1608 12.2093V11.9646C70.1608 10.5998 69.1468 9.55045 66.6265 9.55045C64.1062 9.55045 62.8476 10.6706 62.709 12.5247V12.7339H59.2455C59.3516 9.02576 62.2905 6.78552 66.6619 6.78552C71.0333 6.78552 73.415 8.67498 73.415 12.8725V21.0228L73.4504 23.5755H70.1254V21.0906C69.0053 23.0508 66.9419 23.9941 64.5631 23.9941C61.0289 23.9941 58.8594 22.0692 58.8594 19.2365V19.2424ZM70.1608 16.9344V14.9064C69.1468 15.605 67.3959 15.885 65.7864 16.2712C63.897 16.7605 62.3583 17.3559 62.3583 19.1039C62.3583 20.4686 63.3723 21.3765 65.191 21.3765C67.6051 21.3765 69.8778 20.1532 70.1578 16.9344H70.1608Z"
          fill="#F3744A"
        />
        <path
          d="M75.6758 7.06847H79.1039V10.1134C79.5932 8.18859 80.8873 6.92993 83.8969 6.92993H84.4215V10.2873H82.8475C79.9086 10.2873 79.1039 11.7582 79.1039 15.1156V23.5814H75.6758V7.06847Z"
          fill="#F3744A"
        />
        <path
          d="M84.7368 15.3928C84.7368 10.1459 87.8849 6.71481 92.1885 6.71481C94.4612 6.71481 96.3153 7.79956 97.5062 9.54753V0H100.864V23.5814H97.5062V21.1673C96.3507 22.9182 94.4612 23.9322 92.1532 23.9322C87.9203 23.9322 84.7368 20.7487 84.7368 15.3957V15.3928ZM97.683 15.3928V15.1128C97.683 11.8585 95.935 9.65365 93.0286 9.65365C90.1222 9.65365 88.3418 11.717 88.3418 15.2867V15.5313C88.3418 19.0656 90.1959 20.9904 93.0286 20.9904C95.8614 20.9904 97.683 18.6824 97.683 15.3928Z"
          fill="#F3744A"
        />
        <path
          d="M103.381 19.2778C103.381 15.8143 106.32 14.8681 109.503 14.1695C111.953 13.6094 114.225 13.2587 114.962 12.0708V11.8968C114.962 9.41195 113.633 8.15329 110.553 8.15329C107.752 8.15329 105.654 9.16729 105.621 12.003V12.1415H103.838C103.873 8.43332 106.706 6.71777 110.556 6.71777C114.614 6.71777 116.678 8.57186 116.678 12.28V22.0398L116.713 23.5785H114.93V20.3596C113.53 23.1599 111.08 23.9293 108.878 23.9293C105.415 23.9293 103.387 22.0044 103.387 19.2749L103.381 19.2778ZM114.962 17.8777V13.6094C114.019 14.6588 111.814 14.9742 109.748 15.4282C107.369 15.9882 105.2 16.7576 105.2 19.2425C105.2 21.2027 106.529 22.5321 109.049 22.5321C112.094 22.5321 114.158 20.9226 114.962 17.8777Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M119.127 7.06857H120.772V10.706C121.403 8.32723 122.836 7.0332 125.881 7.0332H126.859V8.64264H125.53C121.822 8.64264 120.878 10.9507 120.878 15.3958V23.5815H119.127V7.06857Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M126.859 15.325C126.859 10.1813 130.114 6.71777 134.942 6.71777C139.001 6.71777 141.589 9.06118 142.394 12.454L140.678 12.8401C140.083 10.0752 138.158 8.15329 134.942 8.15329C131.024 8.15329 128.61 10.9536 128.61 15.2543V15.3957C128.61 19.5932 130.989 22.4643 134.907 22.4643C138.196 22.4643 140.121 20.5394 140.713 17.7421L142.429 18.1607C141.554 21.6596 139.001 23.9322 134.907 23.9322C130.114 23.9322 126.859 20.398 126.859 15.325Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M144.813 0H146.494V11.1599C147.472 8.29182 149.397 6.71776 152.336 6.71776C155.799 6.71776 158.075 8.99042 158.075 13.0494V23.5814H156.292V13.1909C156.292 9.93663 154.682 8.25645 152.059 8.25645C148.28 8.25645 146.497 11.4046 146.497 16.9668V23.5785H144.816V0H144.813Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M160.731 1.78333H162.865V3.95282H160.731V1.78333ZM160.94 7.06557H162.691V23.5785H160.94V7.06851V7.06557Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M168.148 19.8378V8.53642H164.929V7.06848H168.148V3.46346L169.828 2.16943V7.06848H174.025V8.53642H169.792V19.6285C169.792 21.3765 170.352 22.0781 172.068 22.1134H173.993V23.5814H171.823C169.058 23.546 168.151 22.3581 168.151 19.8378H168.148Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M174.933 15.3604C174.933 10.2874 178.187 6.71777 182.91 6.71777C187.632 6.71777 190.326 9.93664 190.326 15.1835V15.8143H176.646C176.752 19.6964 179.06 22.4613 182.945 22.4613C186.515 22.4613 188.263 20.6426 189.032 18.5792L190.5 19.1039C189.589 21.6566 187.281 23.9322 182.942 23.9322C177.94 23.9322 174.93 20.3626 174.93 15.3604H174.933ZM188.507 14.4495C188.436 10.4967 186.302 8.18572 182.874 8.18572C179.446 8.18572 176.926 10.6706 176.646 14.4495H188.507Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M192.46 15.325C192.46 10.1813 195.714 6.71777 200.543 6.71777C204.601 6.71777 207.19 9.06118 207.994 12.454L206.279 12.8401C205.683 10.0752 203.758 8.15329 200.543 8.15329C196.625 8.15329 194.211 10.9536 194.211 15.2543V15.3957C194.211 19.5932 196.59 22.4643 200.507 22.4643C203.797 22.4643 205.722 20.5394 206.314 17.7421L208.03 18.1607C207.154 21.6596 204.601 23.9322 200.507 23.9322C195.714 23.9322 192.46 20.398 192.46 15.325Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M211.702 19.8378V8.53642H208.483V7.06848H211.702V3.46346L213.382 2.16943V7.06848H217.58V8.53642H213.347V19.6285C213.347 21.3765 213.907 22.0781 215.623 22.1134H217.548V23.5814H215.378C212.613 23.546 211.705 22.3581 211.705 19.8378H211.702Z"
          fill="#191D20"
          className="dark-img"
        />
        <path
          d="M218.697 20.8519L220.027 19.9411C221.565 21.8659 223.876 22.5645 225.834 22.5645C228.386 22.5645 229.996 21.3059 229.996 19.2749C229.996 17.244 227.826 16.5454 224.923 15.8468C221.565 15.0774 219.361 14.2727 219.361 11.2631C219.361 8.63968 221.704 6.71484 224.887 6.71484C227.475 6.71484 229.61 7.76422 231.325 9.58293L230.066 10.5616C228.737 8.9875 226.812 8.11204 224.855 8.11204C222.512 8.11204 221.005 9.33533 221.005 11.1894C221.005 13.2528 222.721 13.6743 225.728 14.3729C228.631 15.0391 231.641 16.0531 231.641 19.0951C231.641 22.1371 229.333 23.9588 225.798 23.9588C223.033 23.9588 220.271 22.9448 218.694 20.846L218.697 20.8519Z"
          fill="#191D20"
          className="dark-img"
        />
      </g>
      <defs>
        <clipPath id="clip0_522_1991">
          <rect width="231.643" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
