import React from "react";

export default function PrivileeLogoSvg() {
  return (
    <svg viewBox="0 0 228 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full dark-txt">
      <path
        d="M21.2743 0.128906C24.018 0.128906 26.1913 0.889056 27.7978 2.40812C29.4031 3.92842 30.2064 5.96001 30.2064 8.50534C30.2064 11.1739 29.3723 13.2129 27.7042 14.621C26.0361 16.0292 23.609 16.7339 20.4206 16.7339H4.44756V26.9633H0V0.128906H21.2743ZM20.4969 12.7311C23.9306 12.7311 25.648 11.3476 25.648 8.58049C25.648 5.61505 23.832 4.13171 20.2 4.13171H4.44756V16.7339C4.44756 16.7339 8.21504 12.7582 12.6182 12.7582C16.9808 12.7582 20.4969 12.7311 20.4969 12.7311ZM55.8519 12.7028C59.2855 12.7028 61.003 11.3193 61.003 8.55216C61.003 5.58671 59.187 4.10337 55.5538 4.10337H39.7693V16.7869C39.7693 16.7869 43.6156 12.7237 48.1001 12.7237C52.4627 12.725 55.8519 12.7028 55.8519 12.7028ZM65.7511 26.9633H60.5779L53.3152 16.7339L39.7693 16.7881V27.0175L35.3217 26.9645V0.128906H56.4852C59.3016 0.128906 61.513 0.889056 63.1196 2.40812C64.7249 3.92842 65.5294 5.98465 65.5294 8.57926C65.5294 10.8523 64.8739 12.6806 63.5643 14.0654C62.3532 15.3492 60.6851 16.1647 58.5611 16.5109L65.7511 26.9633ZM72.6824 26.9633H77.13V0.128906H72.6824V26.9633ZM110.856 0.128906L98.8121 22.4887L86.7668 0.128906H81.7981L96.2545 26.9633H101.369L115.824 0.128906H110.856ZM120.494 26.9633H124.942V0.128906H120.494V26.9633ZM168.747 22.9605V16.729H184.466V12.7262H176.89C172.486 12.7262 168.759 16.729 168.747 16.729V4.13171H191.361V0.128906H164.303V26.9633H192.397V22.9605H168.747ZM146.395 22.9605C141.85 23.0443 138.369 26.9633 138.369 26.9633L138.358 0.128906H133.911V26.9633H159.077V22.9605H146.395ZM203.501 22.9605V16.729H219.22V12.7262H211.643C207.24 12.7262 203.513 16.729 203.501 16.729V4.13171H226.114V0.128906H199.057V26.9633H227.151V22.9605H203.501Z"
        fill="#313F53"
      ></path>
    </svg>
  );
}
